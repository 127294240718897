import React, { FC } from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';

export interface SummaryCardProps {
    icon?: string;
    title?: string | React.ReactElement;
    content?: string | React.ReactElement;
    background?: string;
    footer?: boolean | string | React.ReactElement;
    cardLink?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentText: {
            background: theme.palette.text.primary,
            color: theme.palette.secondary.contrastText,
            minHeight: '110px',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')] :{
                minHeight: 'auto',
            },
            [theme.breakpoints.up('xl')] :{
                minHeight: 'auto',
            }
        },
    })
);

const SummaryCard: FC<SummaryCardProps & RouteComponentProps> = ({ icon= 'warning', title= 'title', content='content', footer = false, history, cardLink }) => {
    const classes = useStyles();

    const navigateToLocation = () => {
        if ( cardLink && cardLink.trim() !== '' ) {
            history.push(cardLink);
        }
    }

    return (
        <Card style={{ height: '100%' }}>
            <CardActionArea>
                <CardContent className={classes.contentText} onClick={navigateToLocation}>
                    <Box display="flex" alignItems="flex-star">
                        <Box pr={2}>
                            <Icon>{icon}</Icon>
                        </Box>
                        <Typography>
                            {title}
                        </Typography>
                    </Box>
                    <Typography variant="h5">
                        {content}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {
                footer && <CardActions>
                    {footer}
                </CardActions>
            }
        </Card>
    );
}

export default withRouter(SummaryCard);
