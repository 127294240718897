import { encode } from 'querystring';

import {API_NAME, ChargeCard, ContractBillOverview} from '../state/types';
import {API, Auth} from 'aws-amplify';
import {useState} from "react";

const {
    REACT_APP_APIGW_URL
} = process.env;

const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export const API_BASE = REACT_APP_APIGW_URL || 'https://48lg2kt3gk.execute-api.eu-central-1.amazonaws.com/stage';
console.log(`Server API Base: ${API_BASE}`);

export const createApiURL = (path: string, params: any = null) => {

    let qs = '';
    if (params) {
        qs = `?${encode(params)}`;
    }
    return `/${path}${qs}`;
}

export const getAPIParams = async () => {
    const user = await Auth.currentSession().catch(ex => { console.log("No user session existing"); });

    if (user) {
        const {
            sub: userId
        } = user.getIdToken().payload;

        return { API_NAME, userId }
    }

    return null;
}

export const downloadItem = async (contract_id: string, billing_year: string, billing_month: string, type: 'invoice' | 'pdf' | '', billing_id?: string) => {
    const params = await getAPIParams();
    if (params) {
        const { API_NAME, userId } = params;

        const { presignedUrl } = billing_id ? await API.get(API_NAME, createApiURL(`customer/${userId}/${contract_id}/${billing_id}/${billing_year}${billing_month}/generic-invoice`), {}) :
            await API.get(API_NAME, createApiURL(`customer/${userId}/cdr/${contract_id}/${billing_year}${billing_month}/${type}`), {});

        if(detectMobile()) {
            window.location = presignedUrl;
        }
        else {
            window.open(presignedUrl,'_self');
        }
    } else {
        // TODO throw error, something went wrong here
    }
}

export const updateCardComment = async(card: ChargeCard) => {
    const { card_id, annotation } = card;
    let myInit = {
        headers: {
            'Content-Type': 'application/json'
        }, body: {
            card: {
                'id': card_id,
                'annotation': annotation
            }
        }
    }
    const params = await getAPIParams();
    if (params) {
        const { API_NAME, userId } = params;
        const { updatedCard } = await API.post(API_NAME, createApiURL(`customer/${userId}/card/${card_id}`), myInit);

        return updatedCard;
    } else {
        return null;
    }
}

export const getCDRJSON = async (contract_id: string, billing_year: string, billing_month: string) => {
    const params = await getAPIParams();
    if (params) {
        const { API_NAME, userId } = params;
        //const { contract_id, billing_year, billing_month } = bill;
        const jsonCDR = await API.get(API_NAME, createApiURL(`customer/${userId}/cdr/${contract_id}/${billing_year}${billing_month}`), {});

        return jsonCDR;
    } else {
        // TODO throw error, something went wrong here
    }
}