import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import WaydoCSSApp from './waydo-css-app';

import { Provider } from 'react-redux';
import { createAppState } from './state';
import { resolveConfigFromUrl } from './state/operations';

import { name, version } from "../package.json";

console.log(`Name: ${name}`);
console.log(`Version: ${version}`);

const store = createAppState();

// TODO change me back to real config resolving
// store.dispatch(resolveConfigFromHardcodedData() as any);
// this is the real one
store.dispatch(resolveConfigFromUrl() as any);

ReactDOM.render(
    <Provider store={store}>
        <WaydoCSSApp />
    </Provider>,
    document.getElementById('root'));
