import {
    SetContractsData, SetContractsError, SetContractsLoading, ClearContractsError, SetContractBillDetails,
    SET_CONTRACTS_DATA, SET_CONTRACTS_ERROR, SET_CONTRACTS_LOADING, CLEAR_CONTRACTS_ERROR, SET_CONTRACTS_BILL_DATA,
    Contract, ContractBillDetail
 }
from './types';
import { AppError } from '../errors';

export const setContractsData = (data: Contract[]): SetContractsData => ({
    type: SET_CONTRACTS_DATA,
    data
});

export const setContractsError = (error?: AppError): SetContractsError => ({
    type: SET_CONTRACTS_ERROR,
    error,
});

export const setContractsLoading = (isLoading: boolean): SetContractsLoading => ({
    type: SET_CONTRACTS_LOADING,
    isLoading
});

export const clearContractsError = (): ClearContractsError => ({
    type: CLEAR_CONTRACTS_ERROR
});

export const setContractBillDetails = (data: ContractBillDetail[]): SetContractBillDetails => ({
    type: SET_CONTRACTS_BILL_DATA,
    data
});

