import {
    SetUserLoading, SetUserData, SetUserError, ClearUserError, ClearUserData,
    SET_USER_LOADING, SET_USER_DATA, SET_USER_ERROR, CLEAR_USER_ERROR, CLEAR_USER_DATA,
    CSSUser
} from './types';
import { AppError } from '../errors';

export const setUserLoading = (isLoading: boolean): SetUserLoading => ({
    type: SET_USER_LOADING,
    isLoading
});

export const setUserData = (userData: CSSUser): SetUserData => ({
    type: SET_USER_DATA,
    userData
});

export const clearUserData = (): ClearUserData => ({
    type: CLEAR_USER_DATA
})

export const setUserError = (error?: AppError): SetUserError => ({
    type: SET_USER_ERROR,
    error
});

export const clearUserError = (): ClearUserError => ({
    type: CLEAR_USER_ERROR
});