import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import {downloadItem, updateCardComment} from '../../../core/api-utils';

import { useStyles } from './contract-row';
import {ChargeCard, ContractBillOverview} from './../../../state/contracts/types';
import Button from "@material-ui/core/Button";
import LoadingIndicator from "../../../components/loading-indicator";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export interface CardDetailsProps {
    cards: ChargeCard[];
}

const CardDetails: FC<CardDetailsProps> = ({ cards }) => {
    const classes = useStyles();
    const [cardComment, setCardComment] = useState('');
    const [ panelState, updatePanels ] = useState({} as { [id:string]: boolean });
    const [updateCardLoading, setUpdateCardLoading] = useState(false);

    const setPanelState = (key: string, value: boolean, comment: string) => {
        updatePanels({
            ...panelState,
            [key]: value
        });
        setCardComment(comment);
    };

    const addCardComment = async (card: ChargeCard) => {
        setUpdateCardLoading(true);
        card.annotation = cardComment;
        await updateCardComment(card);
        setPanelState(card.card_id, false, card.annotation);
    }

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                id={`cards-panel-header`}
                classes={{
                    root: classes.panelSummary
                }}
            >
                <div className={classes.column}>
                    <Typography className={classes.heading}><FormattedMessage id="label.cards" /></Typography>
                </div>
                <div className={classes.column}>
                    <Typography className={classes.secondaryHeading}><FormattedMessage id="message.cardIncluded" values={{ number: cards.length}} /></Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                classes={{
                    root: classes.panelDetailsOverride
                }}
            >
                <div className={classes.gridItem}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><FormattedMessage id="tabel.header.card" /></TableCell>
                                <TableCell><FormattedMessage id="tabel.header.card-active" /></TableCell>
                                <TableCell><FormattedMessage id="tabel.header.annotation" /></TableCell>
                                {/*<TableCell><FormattedMessage id="tabel.header.valid-until" /></TableCell>
                                <TableCell><FormattedMessage id="tabel.header.tariff" /></TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                cards.map((card) => (
                                    <TableRow key={card.cardid || card.card_id}>
                                        <TableCell>{card.cardid || card.card_id}</TableCell>
                                        <TableCell>{card.active? <FormattedMessage id="tabel.card.active" />: <FormattedMessage id="tabel.card.inactive" />}</TableCell>
                                        <TableCell>
                                            <Input
                                                onChange={e => setPanelState(card.card_id, true, e.target.value)}
                                                defaultValue={card.annotation}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => addCardComment(card)}
                                                            disabled={ !panelState[`${card.card_id}`] }
                                                        >
                                                            {
                                                                <SaveIcon className={!panelState[`${card.card_id}`] ? classes.hidden : ''}/>
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
                {/*<div className={classes.panelBodyActions}>
                    <Button variant="contained" color="secondary">
                        <FormattedMessage id="label.save" />
                    </Button>
                </div>*/}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default CardDetails;
