import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import { Contract } from './../../../state/contracts/types';
import CardDetails from './card-details';
import BillingDetails from './billing-details';
import { parseUrl } from '../../../core/url-utils';

export interface ContractRowProps {
    contract: Contract;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typeAvatar: {
            fontWeight: 'bold',
            color: [theme.palette.common.white , " !important"].join(''),
            backgroundColor: [theme.palette.secondary.main , " !important"].join(''),
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: theme.spacing(1)
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        column: {
            flexBasis: '33.33%',
            [theme.breakpoints.down('xs')] :{
                flexBasis: '50%',
            }
        },
        title: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: theme.spacing(1)
        },
        panelDetailsOverride: {
            padding: 0,
            flexDirection: 'column'
        },
        panelBodyActions: {
            display: 'flex',
            flexDirection: 'row-reverse',
            marginBottom: '5px',
            marginRight: '5px',
            '&> *' : {
                marginLeft: theme.spacing(1),
                marginBottom: theme.spacing(1),
            }
        },
        headerAction : {
            textAlign: 'right',
        },
        detailLink : {
            textDecoration: 'none',
            color: '#37373C',
        },
        gridItem: {
            width: '100%',
            overflowX: 'auto',
        },
        panelSummary: {
            [theme.breakpoints.down('xs')] :{
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        },
        panelDetails: {
            [theme.breakpoints.down('xs')] :{
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        },
        hidden: {
            visibility: 'hidden',
        }
    })
);

const ContractRow: FC<ContractRowProps> = ({ contract }) => {
    const classes = useStyles();

    return (
        <ExpansionPanel key={contract.contract_id} >
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                id={`contract-panel-${contract.contract_id}-header`}
                classes={{ root: classes.panelSummary }}
            >
                <div className={classes.column}>
                    <Typography className={classes.heading}><FormattedMessage id="label.contract" />-{contract.name}</Typography>
                    {
                        contract.category ? <Chip
                        avatar={<Avatar className={classes.typeAvatar}>{contract.category.charAt(0)}</Avatar>}
                        label={contract.category}
                        color="primary"
                    /> : <Chip
                            avatar={<Avatar className={classes.typeAvatar}>S</Avatar>}
                            label={'Sonstiges'}
                            color="primary"
                        />
                    }
                </div>
                <div className={classes.column}>
                    {
                        contract.cards.length > 0 && <Typography className={classes.secondaryHeading}><FormattedMessage id="message.cardIncluded" values={{ number: contract.cards.length}}/></Typography>
                    }
                    <Typography className={classes.secondaryHeading}><FormattedMessage id="message.billIncluded" values={{ number: contract.bills.length}}/></Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                <Grid container spacing={2}>
                    {
                        contract.cards.length > 0 && <Grid item sm={12} className={classes.gridItem}>
                            <Typography className={classes.title}><FormattedMessage
                                id="label.cardDetails"/></Typography>
                            <CardDetails cards={contract.cards}/>
                        </Grid>
                    }
                    <Grid item sm={12} className={classes.gridItem}>
                        <Typography className={classes.title}><FormattedMessage id="label.billDetails" /></Typography>
                        <BillingDetails bills={contract.bills} />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default ContractRow;
