import { useCallback } from 'react';
import { language } from '../providers/language-provider';
import useTranslate from './use-translate';

export type convertNumberToKwhFuncType = (number: number) => string
export type convertSecondsToReadableFuncType = (number: number) => string

export default function useConverter() {
    const { translate } = useTranslate();

    const convertNumberToKwh = useCallback<convertNumberToKwhFuncType>(
    (number) => {
            return new Intl.NumberFormat(`${language}-${language.toUpperCase()}`, { maximumFractionDigits: 3 }).format(number);
    }, []);

    const convertSecondsToReadable = useCallback<convertSecondsToReadableFuncType>(
    (seconds) => {
        const days = Math.floor(seconds / (24 * 3600));
        seconds = seconds % (24 * 3600);
        const hours = Math.floor(seconds / 3600);
        seconds = seconds % 3600;
        const minutes = Math.floor(seconds / 60);

        let formatted = '';
        formatted += days !== 0 ? `${days} ${translate('label.day')}, ` : '';
        formatted += hours !== 0 ? `${hours} ${translate('label.hour')}, ` : '';
        formatted += minutes !== 0 ? `${minutes} ${translate('label.minute')}` : '';
        return formatted;
    }, [translate]);

    return {
        convertNumberToKwh,
        convertSecondsToReadable,
    };
}
