import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import useTranslate from './../../../hooks/use-translate';

import { CSSUser } from './../../../state/user/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(5),
            position: 'relative'
        },
        paper: {
            [theme.breakpoints.up('sm')] :{
                maxWidth: '50%',
                margin: `${theme.spacing(1)}px auto`,
                padding: theme.spacing(2),
            }
        },
        avatar: {
            position: 'absolute',
            width: theme.spacing(12),
            height: theme.spacing(12),
            top: `-${theme.spacing(6)}px`,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
            fontSize: theme.spacing(4),
        },
        userDetails: {
            marginTop: theme.spacing(8),
            '& > *': {
                margin: theme.spacing(2),
                display: 'flex',
            },
        },
        userDetailsGroup: {
            [theme.breakpoints.down('xs')] : {
                flexFlow: 'column',
            },
            '& > *': {
                display: 'flex',
                flexGrow: 1,
            },
        },
        spacer: {
            width: theme.spacing(1),
            flexGrow: 0,
             [theme.breakpoints.down('xs')] : {
                height: theme.spacing(2),
            },
        },
    })
);

export interface UserDetailsProps {
    user?: CSSUser
}

const UserDetails: FC<UserDetailsProps> = ({ user }) => {
    const classes = useStyles();
    const { translate } = useTranslate();
    if(!user) {
        return <Box><FormattedMessage id="label.unexpectedError" /></Box>;
    }
    const avatarLetter: string = user.firstName || user.email || "A";
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Avatar className={classes.avatar}>{avatarLetter.substring(0, 1).toUpperCase()}</Avatar>
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            <Box className={classes.userDetails}>
                                <TextField disabled id="firstName" label={translate('label.firstName')} defaultValue={user.firstName} variant="outlined" />
                                <TextField disabled id="lastName" label={translate('label.lastName')} defaultValue={user.lastName} variant="outlined" />
                                <TextField disabled id="email" label={translate('label.email')} defaultValue={user.email} variant="outlined" />
                                <Box className={classes.userDetailsGroup}>
                                    <TextField disabled id="street" label={translate('label.street')} defaultValue={user.address.street} variant="outlined" />
                                    <Box className={classes.spacer}/>
                                    <TextField disabled id="houseNumber" label={translate('label.houseNumber')} defaultValue={user.address.houseNumber} variant="outlined" />
                                </Box>
                                <Box className={classes.userDetailsGroup}>
                                    <TextField disabled id="zip" label={translate('label.zip')} defaultValue={user.address.zip} variant="outlined" />
                                    <Box className={classes.spacer}/>
                                    <TextField disabled id="city" label={translate('label.city')} defaultValue={user.address.city} variant="outlined" />
                                    <Box className={classes.spacer}/>
                                    <TextField disabled id="country" label={translate('label.country')} defaultValue={user.address.country} variant="outlined" />
                                </Box>
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default UserDetails;
