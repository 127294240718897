import React, { FC } from 'react';

import clsx from 'clsx';
import { Link, withRouter, RouteComponentProps, matchPath } from 'react-router-dom';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Icon from '@material-ui/core/Icon';

export interface MenuTitleProps {
    pathname: string;
}

export type TitleFn = (props: MenuTitleProps) => string;

export interface MenuItem {
    name: string;
    icon: string;
    slug: string;
    component: any; // TODO whats the correct type for any react component??
    visible: boolean;
    title?: string | TitleFn;
}

interface NavigationProps {
    menu: MenuItem[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0
        },
        routerLink: {
            textDecoration: 'none',
            color: theme.palette.text.secondary
        },
        linkActive: {
            background: theme.palette.primary.main,
            display: 'block',
            color: theme.palette.text.primary,
        },
        linkActiveText: {
            fontWeight: theme.typography.fontWeightBold,
        },
        activeIcon: {
            color: theme.palette.text.primary,
        }
    })
);

const Navigation: FC<NavigationProps & RouteComponentProps> = ({ menu, location } ) => {

    const classes = useStyles();

    return (
        <List className={classes.root}>
            {menu.filter(s => s.visible).map(({icon, name, slug}) => {
                const isActive = matchPath(location.pathname, {path: slug, exact: true, strict: false})
                return (
                    <Link
                        key={slug} to={slug}
                        className={clsx(
                            classes.routerLink,
                            {
                                [classes.linkActive]: isActive
                            }
                        )} >
                        <ListItem button >
                            <ListItemIcon>
                                <Icon className={clsx({[classes.activeIcon]: isActive})}>
                                    {icon}
                                </Icon>
                            </ListItemIcon>
                            <ListItemText classes={{primary: clsx({[classes.linkActiveText]: isActive})}} primary={name} />
                        </ListItem>
                    </Link>
                )
            })}
        </List>
    )
}

export default withRouter(Navigation);
