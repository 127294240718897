import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import {
    Link,
    Switch,
    Route,
    withRouter,
    matchPath,
    RouteComponentProps
} from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import CssBaseLine from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Greetings, UsernameAttribute } from 'amplify-material-ui';

import useTranslate from './../../hooks/use-translate';
import { CSSTheme } from '../../state/theme/types';
import Navigation from '../../components/navigation';
import getMenuItems from './menu-items';
import useStyles from './use-styles';
import NotFound from '../not-found';

export interface RootLayoutProps {
  currentTheme: CSSTheme;
}

export interface RootLayoutActions {
    fetchUserInfo: () => any;
    clearUserData: () => any;
  }

const RootLayout: React.FC<RootLayoutProps & RouteComponentProps & RootLayoutActions> = ({ currentTheme, location,fetchUserInfo, clearUserData }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const classes = useStyles();
    const { translate } = useTranslate();
    const [open, setOpen] = useState(true);

    const toggleDrawer = () => {
        setOpen(state => !state);
    }

    useEffect(() => {
        console.log("Root layout attched");

        setOpen(matches);

        fetchUserInfo();

        return () => {
            console.log("Root layout detached");
            clearUserData();
        }
    }, [matches]);

    const menuItems = getMenuItems(translate)
    const currentMenuItem = menuItems.find((item) =>
        matchPath(location.pathname, {path: item.slug, exact: true, strict: false})
    );

    let menuText = '';
    if(currentMenuItem) {
        menuText = currentMenuItem.title
            ? typeof currentMenuItem.title === 'function'
                ? currentMenuItem.title({
                    pathname: location.pathname
                })
                : currentMenuItem.title
            : currentMenuItem.name
    }
    return (
        <div className={classes.root}>
            <CssBaseLine />
            <Greetings
                usernameAttribute={UsernameAttribute.EMAIL}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
                burgerMenu={
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                        >
                        <MenuIcon />
                    </IconButton>
                }
                title={<div className={classes.titleWrapper}>
                  <Link to="/" className={clsx(classes.titleLink, { [classes.titleLinkHide]: open })}>
                      <div className={classes.titleImage} style={{backgroundImage:`url(waydo-logo.svg)`}}></div>
                  </Link>
                  <Typography component="h1" variant="h6">{ menuText }</Typography>
                </div>}
            />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div className={clsx(classes.toolbar, classes.headerToolbar)}>
                    <Link to="/" className={clsx(classes.titleLink, { [classes.titleLinkHide]: !open })}>
                        <div className={classes.titleImage} style={{backgroundImage:`url(waydo-logo.svg)`}}></div>
                    </Link>
                    <IconButton onClick={toggleDrawer} color="primary">
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : (open ? <ChevronLeftIcon /> : <ChevronRightIcon />)}
                    </IconButton>
                </div>
                {/*<Divider />*/}
                <Navigation menu={menuItems}/>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    {
                        menuItems.map(({slug, component: RouteOutlet}) => {
                            return <Route path={slug} key={slug}><RouteOutlet/></Route>;
                        })
                    }
                    <Route path="*"><NotFound/></Route>
                </Switch>
            </main>
        </div>
    );
};

export default withRouter(RootLayout);
