import { SetThemeAction, AddThemeAction, SET_THEME, ADD_THEME, CSSTheme } from './types';

export const setTheme = (theme: string): SetThemeAction => ({
    type: SET_THEME,
    theme
});

export const addTheme = (themeName: string, options: CSSTheme): AddThemeAction => ({
    type: ADD_THEME,
    themeName,
    options
})