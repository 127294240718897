import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Link, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: theme.spacing(8),
            padding: theme.spacing(3),
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'fixed',
            bottom: 0,
            boxSizing: 'border-box',
            background: theme.palette.common.white,
        },
        links : {
            marginLeft: theme.spacing(2),
        },
        firstlink : {
            marginLeft: 0,
        }
    })
);

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
    const classes = useStyles();
    return (
        <Paper elevation={4} className={classes.root}>
            <Link underline="none" target="_blank" href="https://www.ewe-go.de/datenschutzerklaerung/" className={clsx(classes.links, classes.firstlink)}>
                <FormattedMessage id="footer.link.disclaimer" />
            </Link>
            <Link underline="none" target="_blank" href="https://www.ewe-go.de/impressum/" className={classes.links}>
                  <FormattedMessage id="footer.link.imprint" />
            </Link>
        </Paper>
    )
}

export default Footer
