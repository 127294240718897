import React from 'react';
import useTranslate from './../hooks/use-translate';
import { useSelector } from 'react-redux';

import { AppState } from './../state/types';
import LoadingIndicator from "./../components/loading-indicator"
import AppError from "../components/ui-app-error"

const withWaitForConfig = (Component: React.ElementType) => {
    const ConfigurationCheck: React.FC = (props) => {
        const {
            hasConfig,
            hasError,
            isLoading
        } = useSelector((state: AppState) => state.config);
        const { translate } = useTranslate();
        const isConfigValid = !hasError && !isLoading && hasConfig;
        return isConfigValid ? (
            <Component {...props}/>
        ) : hasError ? (
                <AppError />
            ) : (
                <LoadingIndicator fullScreen message={translate('label.loading')} />
            );
    }
    return ConfigurationCheck;
}

export default withWaitForConfig;
