import {
    ConfigState, ConfigActionTypes,
    SET_CONFIG_DATA, SET_CONFIG_LOADING, SET_CONFIG_PATH, SET_CONFIG_ERROR, CLEAR_CONFIG_ERROR
} from './types';

const initialState: ConfigState = {
    error: null,
    hasConfig: false,
    hasError: false,
    isLoading: false
};

const config = (state = initialState, action: ConfigActionTypes) => {
    switch(action.type) {
        case SET_CONFIG_DATA:
            return {
                ...state,
                hasConfig: true,
                config: action.data
            }
        case SET_CONFIG_LOADING:
            return {
                ...state,
                isLoading: action.loading
            }
        case SET_CONFIG_PATH:
            return {
                ...state,
                hasConfig: false,
                configPath: action.path
            };
        case SET_CONFIG_ERROR:
            return {
                ...state,
                error: action.error,
                hasError: true,
                hasConfig: false,
                isLoading: false
            }
        case CLEAR_CONFIG_ERROR:
            return {
                ...state,
                error: null,
                hasError: false
            }
        default:
            return state;
    }
}

export default config;