export const SET_THEME = 'theme/set';
export const ADD_THEME = 'theme/add';

export interface CSSTheme {
    mui: any; // Material UI Theme props
}

export interface ThemeState {
    currentTheme: string;
    themes: { [id: string]: CSSTheme }
}

export interface SetThemeAction {
    type: typeof SET_THEME;
    theme: string;
}

export interface AddThemeAction {
    type: typeof ADD_THEME;
    themeName: string,
    options: any;
}

export type ThemeActionTypes = SetThemeAction | AddThemeAction;

