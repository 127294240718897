import React, { Component, createRef, RefObject } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import * as d3 from 'd3';

export interface ChartMargin {
    top: number;
    left: number;
    bottom: number;
    right: number;
};

export interface BarchartProps {
    width: number;
    height: number;
    margin?: ChartMargin;
}

class Barchart extends Component<BarchartProps>{

    static defaultProps: BarchartProps & { margin: ChartMargin } = {
        width: 100,
        height: 100,
        margin: {
            top: 10,
            left: 40,
            bottom: 20,
            right: 10
        }
    }

    ref: RefObject<SVGSVGElement>;
    x?: d3.ScaleBand<string>;
    y?: d3.ScaleLinear<number, number>;
    xAxis?: (g: any) => any; // TODO change to real type
    yAxis?: (g: any) => any;

    constructor(props: BarchartProps) {
        super(props);
        this.ref = createRef();
    }

    getPropsWithDefault() {
        return { ...Barchart.defaultProps, ...this.props };
    }

    createScalers(data: any[]) {
        const { width, height, margin } = this.getPropsWithDefault();

        this.x = d3.scaleBand()
        .domain(data.map(d => d.name))
        .range([margin.left, width - margin.right])
        .padding(0.1);

        this.y = d3.scaleLinear()
            .domain([0, 140])
            .rangeRound([height - margin.bottom, margin.top])
    }

    createAxis() {
        const { height, margin } = this.getPropsWithDefault();
        const { x, y } = this;

        // nullcheck
        if(!x || !y) {
            return;
        }

        this.xAxis = (g: any) => g.attr("transform", `translate(0,${height - margin.bottom})`)
                    .call(d3.axisBottom(x).tickSizeOuter(0))
                    .call((g: any) => g.selectAll(".domain").remove());

        this.yAxis = (g: any) => g.attr("transform", `translate(${margin.left},0)`)
                .call(d3.axisLeft(y).ticks(null, "s"))
                .call((g: any) => g.selectAll(".domain").remove());

        this.updateAxis();
    }

    updateAxis() {
        const { xAxis, yAxis } = this;
        if(!xAxis || !yAxis) {
            return;
        }
        const svg = d3.select(this.ref.current);

        svg.append('g')
            .call(xAxis);

        svg.append('g')
            .call(yAxis);
    }

    componentDidMount() {
        // https://observablehq.com/@d3/stacked-bar-chart
        // https://bl.ocks.org/jamesleesaunders/f32a8817f7724b17b7f1
        // http://bl.ocks.org/Caged/6476579
        const { width, height, margin } = this.getPropsWithDefault();

        const svg = d3.select(this.ref.current);

        const data = [
            {
                'name': 'Januar',
                'Foo': 50,
                'Bar': 25,
                'Baz': 15
            },
            {
                'name': 'Februar',
                'Foo': 15,
                'Bar': 29,
                'Baz': 12
            },
            {
                'name': 'März',
                'Foo': 5,
                'Bar': 22,
                'Baz': 3
            },
            {
                'name': 'April',
                'Foo': 22,
                'Bar': 12,
                'Baz': 76
            }
        ];

        const columns = [
            'name',
            'Foo',
            'Bar',
            'Baz'
        ];

        // defaults
        this.createScalers(data);
        this.createAxis();
        const { x, y } = this;

        // check if everything is ok (typescript...)
        if(!x || !y ) {
            return;
        }



        const series= d3.stack().keys(columns.slice(1))(data as []);

        const color = d3.scaleOrdinal()
            .domain(series.map(d => d.key))
            .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), series.length).reverse())
            .unknown("#ccc");

        const graph = svg.append('g')
            .selectAll('g')
            .data(series)
            .join('g')
                .attr('fill', d => color(d.key) as string)
            .selectAll('rect')
            .data(d => d);

        const rect = graph.enter()
            .append('rect')
                .attr('x', (d, i) => {
                    return x(`${d.data.name}`) as number
                })
                .attr('y', (d, i) => height-margin.bottom)
                .attr('width', x.bandwidth());

        rect.transition()
                .duration(1000)
                .delay((d, i) => i * 50)
                .attr('y', d => y(d[1]))
                .attr('height', d => y(d[0]) - y(d[1]));

        rect.exit()
            .remove();


    }

    setData() {

    }

    refresh() {
        this.updateAxis();
    }

    render() {
        const { width, height } = this.props;
        const elem = d3.select(this.ref.current);

        return <Box display="flex" flexDirection="column">
            <svg ref={this.ref} width={width} height={height} />
            <Button variant="contained" color="primary" onClick={() => {
                this.refresh()
            }}>
                Refresh
            </Button>
        </Box>;
    }
};

export default Barchart;
