import {
    UserActionTypes, UserState, CSSUser,
    SET_USER_DATA, SET_USER_ERROR, SET_USER_LOADING, CLEAR_USER_ERROR, CLEAR_USER_DATA
} from './types';

const initialState: UserState = {
    isLoading: false
};

const user = (state = initialState, action: UserActionTypes): UserState => {
    switch(action.type) {
        case SET_USER_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case SET_USER_DATA:
            return {
                ...state,
                user: action.userData
            }
        case CLEAR_USER_DATA:
            return {
                ...state,
                isLoading: false,
                user: undefined
            }
        case SET_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        case CLEAR_USER_ERROR:
            return {
                ...state,
                error: undefined
            }
        default:
            return state;
    }
}

export default user;
