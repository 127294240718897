import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ContentBox from '../../components/content-box'
import DataTable from '../../components/data-table'
import tableData from './table-data'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            padding: '2rem',
        },
        headerOverride: {
             background: 'black',
        },
        headerTitleOverride: {
             color: 'white',
        },
        contentOverride: {
            padding: 0,
            '&:last-child': {
            paddingBottom: 0,
            },
        },
        gridItem: {
            width: '100%',
        },
     })
);

const MyContent: React.FC = () => (
    <>
        <h1>Content from Component</h1>
        <p>Everyday normal Component content you can read me.</p>
    </>
)

const ContentBoxAndTables: React.FC = () => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item sm={6} className={classes.gridItem}>
                    <ContentBox
                        title="Content Box With normal content - Overriding header style from parent"
                        content="Everyday normal content you can read me. "
                        classes= {{
                            header: classes.headerOverride,
                            headerTitle: classes.headerTitleOverride,
                        }}
                    />
                </Grid>
                <Grid item sm={6} className={classes.gridItem}>
                     <ContentBox
                        title="Content Box With component content and action button"
                        content={<MyContent />}
                        actions={
                            <>
                                <Button size="small" onClick={() => alert('Share Me !!')}>
                                Share
                                </Button>
                                <Button size="small" onClick={() => alert('Learn :) !!')}>
                                Learn More
                                </Button>
                            </>
                         }
                     />
                </Grid>
                <Grid item sm={12} className={classes.gridItem}>
                     <ContentBox content="Content Box Without header"/>
                </Grid>
                <Grid item sm={6} className={classes.gridItem}>
                    <ContentBox
                        title="Content box - with table Component"
                        classes= {{
                            content: classes.contentOverride
                        }}
                        content={
                        <DataTable
                            data={tableData}
                        />
                        }
                    />
                </Grid>
                <Grid item sm={6} className={classes.gridItem}>
                    <DataTable
                    title="Stand-alone Table Component"
                    data={tableData}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContentBoxAndTables
