import React from "react";
import { IntlProvider } from 'react-intl';

// language files
import de from './../translations/de.json';

export interface TrasnlationsType {
  [key: string]: {
    [key: string]: string
  }
}

export const language: string = 'de';
const trasnlations: TrasnlationsType = {
  de
};

type LanguageProviderProps = {};

const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  return (
    <IntlProvider locale={language} messages={trasnlations[language]}>
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
