import React, { FC } from 'react';
import { Redirect } from 'react-router'

export interface NotFoundProps {

}

const NotFound: FC<NotFoundProps> = () => {
    return (<Redirect to="/overview" />);

    // TODO there should be a proper not found page, but first, we need to redirect to overview
    //return <FormattedMessage id="label.notFound" />;
}


export default NotFound;
