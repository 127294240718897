import {
    ThemeState, ThemeActionTypes, SET_THEME, ADD_THEME
} from './types';

const initialState: ThemeState = {
    currentTheme: '',
    themes: { }
};

const style = (state = initialState, action: ThemeActionTypes) => {
    switch(action.type) {
        case SET_THEME:
            return {
                ...state,
                // only apply if theme exists
                currentTheme: state.themes[action.theme] ? action.theme : state.currentTheme
            };
        case ADD_THEME:
            return {
                ...state,
                currentTheme: state.currentTheme && state.currentTheme.length > 0 
                    ? state.currentTheme
                    : action.themeName,
                themes: {
                    ...state.themes,
                    [action.themeName]: action.options
                }
            }
        default:
            return state;
    }
}

export default style;