export type ParamMap = { [id:string]: string };
export type URLContractInfo = {
  contractId: string;
  billDates: string[];
};
export type CssURLParams = {
  contracts: URLContractInfo[];
};

export const parseUrl = (url: string) => {
    const params = url.replace(/^\?/, '')
                        .split('&')
                        .reduce((arr, p) => {
                            const [key, value] = p.split('=');
                            arr[key] = value;
                            return arr;
                        }, {} as ParamMap);

    const res: CssURLParams = { contracts: [] };
    // console.log("Params", params);
    // sample p=1982739713:202001;202002,1982739713:20200
    // parse expansion panel state
    if(params.p) {
        res.contracts = params.p.split(',')
            .map(contract => {
                const [ contractId, billDates ] = contract.split(':');
                return {
                    contractId,
                    billDates: billDates.split(';')
                }
        });
    }

    return res;
};

export const generateUrl = (baseUrl: string, data: any) => {
    const currentParams = parseUrl(baseUrl);


}
