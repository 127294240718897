import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { ContractsState, ChargeVisualizationData, Datum } from '../../state/contracts/types';
import LoadingIndicator from '../../components/loading-indicator';
import ContractRow from './components/contract-row';
import BarChart from './components/bar-chart';

export interface ContratsProps {
    contracts: ContractsState
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingRoot: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
        },
        chartGrid: {
            background: theme.palette.primary.contrastText,
        },
        root: {
            width: '100%',
        },
    })
);

const Contracts: FC<ContratsProps> = ({ contracts: { isLoading, contracts} }) => {
    const classes = useStyles();

    let billData: ChargeVisualizationData[] = [];
    let cardCount: number = 0;

    contracts.map((contract) => {
        cardCount += contract.cards.length;
    });

    cardCount < 200 && contracts.filter(c => c.cards.length > 0 && c.cards.length < 200).map((contract) => {
        contract.bills.map((bill) => {
            Object.keys(bill.aggregates).length && bill.aggregates.cards.forEach((billCard, index) => {
                const cardIndex = billData.map((e) => { return e.label }).indexOf(billCard.cardid);
                const date = new Date(`${bill.billing_year}-${bill.billing_month}`);
                const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });
                const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(date);
                if (cardIndex > -1) {
                    const datumIndex = billData[cardIndex].datums.map((dat) => { return dat.x }).indexOf(`${month} ${year}`);
                    if (datumIndex > -1) {
                        billData[cardIndex].datums[datumIndex].y += billCard.total_kwh;
                    } else {
                        const datum: Datum = {x: `${month} ${year}`, y: billCard.total_kwh};
                        billData[cardIndex].datums.push(datum);
                    }
                } else {
                    const datum: Datum = {x: `${month} ${year}`, y: billCard.total_kwh};
                    let datumArr:Datum[] = [];
                    datumArr.push(datum);
                    let data: ChargeVisualizationData = {label: billCard.cardid, datums: datumArr};
                    data.datums = [];
                    data.datums.push(datum);
                    billData.push(data);
                }
            })
        })
    });

    billData.map((data) => {
        data.datums.sort((a,b) => (new Date(a.x) > new Date(b.x)) ? 1 : ((new Date(b.x) > new Date(a.x)) ? -1 : 0));
        data.datums = data.datums.length > 3 ? data.datums.splice(data.datums.length - 3, 3) : data.datums;
    });

    if(isLoading) {
        return (
            <div className={classes.loadingRoot}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <>
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Box mb={3} className={classes.chartGrid}>
                        {
                            cardCount < 200 && <BarChart visualizationData={billData}/>
                        }
                    </Box>
                </Grid>
            </Grid>
            <div className={classes.root}>
                <Typography variant="h6" gutterBottom>
                    <FormattedMessage id="label.contractList" />
                </Typography>
                {contracts.map((contract) => (<ContractRow key={contract.contract_id} contract={contract}/>))}
            </div>
        </>
    );
}

export default Contracts;
