import React, { FC, useEffect, useState, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    useLocation,
    useHistory
} from 'react-router-dom';

import { Toast } from 'amplify-material-ui';

import useTranslate from './../../hooks/use-translate';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type PasswordNotificationProps = {};

const PasswordNotification: FC<PasswordNotificationProps> = () => {
    const query = useQuery();
    const { translate } = useTranslate();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(query.get('temp-pw-resent')) {
            setOpen(true)
        }
    }, []);

    const handleClose = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        history.push('/');
    };

    return <Toast
        open={open}
        onClose={handleClose}
        variant = 'warning'
        content={translate('message.temp-pw-resent')}
    />;
}

export default PasswordNotification;
