import { AppError } from '../errors';
export const SET_CONFIG_PATH = 'config/set/path';
export const SET_CONFIG_LOADING = 'config/loading';
export const SET_CONFIG_DATA = 'config/set/data';
export const SET_CONFIG_ERROR = 'config/set/error';
export const CLEAR_CONFIG_ERROR = 'config/clear/error';

export interface ConfigData {
    tenantId: string;
    displayName: string;
    dnsDomain: string;
    region: string;
    signInType: string;
    userPoolId: string;
    userPoolWebClientId: string;
    bucketName: string;
}

export interface ConfigState {
    configPath?: string,
    config?: ConfigData,
    hasConfig: boolean,
    error?: AppError | null,
    hasError: boolean,
    isLoading: boolean
}

export interface SetConfigPath {
    type: typeof SET_CONFIG_PATH;
    path: string;
}

export interface SetConfigLoading {
    type: typeof SET_CONFIG_LOADING;
    loading: boolean;
}

export interface SetConfigData {
    type: typeof SET_CONFIG_DATA;
    data: ConfigData;
}

export interface SetConfigError {
    type: typeof SET_CONFIG_ERROR;
    error?: AppError;
}

export interface ClearConfigError {
    type: typeof CLEAR_CONFIG_ERROR;
}

export type ConfigActionTypes = SetConfigPath | SetConfigLoading | SetConfigData | SetConfigError | ClearConfigError;

