import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: ({fullScreen} : {fullScreen: boolean}) => {
            return fullScreen ? {
                position: 'fixed',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width : '100vw',
                height : '100vh',
            } : {}
        },
        message: {
            marginTop: theme.spacing(1),
        }
    })
);

interface LoadingIndicatorProps {
    fullScreen?: boolean;
    message?: string;
}

const LoadingIndicator = ({ fullScreen = false, message = '' }: LoadingIndicatorProps ) => {
    const classes = useStyles({fullScreen})
    return (
        <div className={classes.root}>
            <CircularProgress />
            {message && <Typography component="span" className={classes.message}>
                {message}
            </Typography>}
        </div>
    )
}

export default LoadingIndicator
