import { AppError } from '../errors';

export const SET_CONTRACTS_LOADING = 'contracts/set/loading';
export const SET_CONTRACTS_DATA = 'contracts/set/data';
export const SET_CONTRACTS_ERROR = 'contracts/set/error';
export const CLEAR_CONTRACTS_ERROR = 'contracts/clear/error';
export const SET_CONTRACTS_BILL_DATA = 'contractsbill/set/data';


export interface Contract {
    contract_id: string;
    name: string;
    cards: ChargeCard[];
    bills: ContractBillOverview[];
    category?: string;
}

export interface ChargeCard {
    cardid: string;
    card_id: string;
    active?: boolean;
    annotation?: string;
}
export type CardsMap = { [id: string]: ChargeCard };

export interface ChargeAggregate {
    total_sessions: number;
    total_kwh: number;
    total_duration: number;
}

export type CardChargeAggregate = ChargeCard & ChargeAggregate;

export interface TransformedCDR {
    id: string;
    card_id: string;
    evse_id: string;
    chargepoint_address: string;
    chargetype: string;
    is_roaming_station: boolean;
    ts_start: string;
    ts_end: string;
    metertotal_kwh: string;
    price_per_unit_net: string;
    total: string;
}

export interface ContractBill {
    total_cards: number;
    cards: CardChargeAggregate [];
}

export type ContractBillAggregate = ContractBill & ChargeAggregate;

export interface ContractBillOverview {
    contract_id: string;
    billing_year: string;
    billing_month: string;
    invoice_id?: string;
    contract_name?: string;
    aggregates: ContractBillAggregate;
}

export interface ChargeSession {
    ts_start: string;
    ts_end: string;
    tarif: string;
    metertotal: number;
    metertotal_kwh: number;
    evseid: string;
    chargepoint_address: string;
    chargepointstreet: string;
    chargepointzip: string;
    chargepointcity: string;
    chargepointcountry: string;
    roaming: string;
    is_roaming_station: boolean;
    is_roaming_card: boolean;
    chargetype: string;
    publickey: string;
    link_to_signed_datasets: string;
    type_of_transparency_method: string;
}

export interface CardChargeDetail {
    cardid: string,
    customernumber: string | null,
    charge_sessions: ChargeSession[];
}

export interface ContractBillDetail {
    contract_id: string;
    billing_year: string;
    billing_month: string;
    charges_by_card: CardChargeDetail[];
}

export interface ContractsState {
    isLoading: boolean;
    contracts: Contract[];
    contractBillDetail: ContractBillDetail[];
}

export interface Datum {
    x: string;
    y: number;
}

export interface ChargeVisualizationData {
    label: string;
    datums: Datum[];
}

export interface SetContractsLoading {
    type: typeof SET_CONTRACTS_LOADING;
    isLoading: boolean;
}

export interface SetContractsData {
    type: typeof SET_CONTRACTS_DATA;
    data: Contract[];
}

export interface SetContractsError {
    type: typeof SET_CONTRACTS_ERROR;
    error?: AppError;
}

export interface ClearContractsError {
    type: typeof CLEAR_CONTRACTS_ERROR;
}

export interface SetContractBillDetails {
    type: typeof SET_CONTRACTS_BILL_DATA;
    data: ContractBillDetail[];
}

export type ContractsActionTypes = SetContractsLoading | SetContractsData | SetContractsError | ClearContractsError | SetContractBillDetails;

