import { connect } from 'react-redux';
import { AppState } from '../../state/types';
import UserDetail from './user-detail';

const mapStateToProps = (state: AppState) => {
    return {
      user: state.user
    };
}

const mapDispatchToProps = () => {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
