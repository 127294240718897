import { combineReducers } from 'redux';

import theme from './theme/reducer';
import config from './config/reducer';
import user from './user/reducer';
import contracts from './contracts/reducer';

export default combineReducers({
    theme,
    config,
    user,
    contracts
});