import { connect } from 'react-redux';

import NotFound from './not-found';

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = () => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
