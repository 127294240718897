import React, { FC } from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Conversions from './conversions'
import Barchart from '../../components/barchart';
import ContentBoxAndTables from './content-box-and-tables';
import ErrorAndLoading from './error-and-loading';

export interface PlaygroundProps {

}

const Playground: FC<PlaygroundProps> = () => {

    return <>
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Conversions
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Conversions />
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Content Boxes
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <ContentBoxAndTables />
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Errors and Loaders
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <ErrorAndLoading />
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Charts
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Barchart width={400} height={400}/>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </>;
}

export default Playground;
