import React, { FC } from 'react';
import { Chart } from 'react-charts';
import { useTheme } from '@material-ui/core/styles';
import { ChargeVisualizationData, Datum } from '../../../state/contracts/types';

export interface ChartProps {
	visualizationData: ChargeVisualizationData[];
}

const BarChart: FC<ChartProps> = ({ visualizationData }) => {
	const theme = useTheme();
	// const { data, randomizeData } = useChartConfig({
	//   series: 12,
	//   datums: 7,
	//   dataType: 'ordinal'
	// })

	const series = React.useMemo(
		() => ({
			type: 'bar',
		}),
		[]
	);

	const axes = React.useMemo(
		() => [
			{ primary: true, type: 'ordinal', position: 'bottom' },
			{
				position: 'left',
				type: 'linear',
				hardMin: 0,
				stacked: false,
				format: (d: any) => `${d} kWh`,
			},
		],
		[]
	);

	const getSeriesStyle = React.useCallback(
		() => ({
			color: theme.palette.primary.main,
			transition: 'all .5s ease',
		}),
		[]
	);

	const getDatumStyle = React.useCallback(
		() => ({
			// color: 'blue',
			// transition: 'all .5s ease'
		}),
		[]
	);

	return (
		// A react-chart hyper-responsively and continuously fills the available
		// space of its parent element automatically
		<div
			style={{
				padding: '1rem',
				width: '100%',
				height: '300px',
			}}>
			{/*<button onClick={randomizeData}>Randomize Data</button>*/}
			<Chart
				data={visualizationData}
				series={series}
				axes={axes}
				getSeriesStyle={getSeriesStyle}
				getDatumStyle={getDatumStyle}
				dark
				tooltip
			/>
		</div>
	);
};

export default BarChart;
