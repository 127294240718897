import {
    ContractsActionTypes, ContractsState,
    SET_CONTRACTS_DATA, SET_CONTRACTS_ERROR, SET_CONTRACTS_LOADING, CLEAR_CONTRACTS_ERROR, SET_CONTRACTS_BILL_DATA
} from './types';

const initialState: ContractsState = {
    isLoading: false,
    contracts: [],
    contractBillDetail: [],
};

const config = (state = initialState, action: ContractsActionTypes) => {
    switch(action.type) {
        case SET_CONTRACTS_DATA:
            return {
                ...state,
                contracts: action.data
            }
        case SET_CONTRACTS_ERROR:
            return {
                ...state
            }
        case SET_CONTRACTS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case CLEAR_CONTRACTS_ERROR:
            return {
                ...state
            }
        case SET_CONTRACTS_BILL_DATA:
            return {
                ...state,
                contractBillDetail: action.data
            }
        default:
            return state;
    }
}

export default config;
