
// redux
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// initial actions
import { addTheme, setTheme } from './theme/actions';

// initial operations

const baseTheme = {
    mui: {
        typography: {
            fontFamily: [
                '"Open Sans"',
                '"sans-serif"',
            ].join(','),
            button: {
              fontWeight: 600,
            },
        },
        palette: {
            common: {
                black: "rgba(50, 64, 66, 1)",
                white: "#fff"
            },
            background: {
                paper: "#fff",
                default: "#fafafa"
            },
            primary: {
                light: "rgba(255, 248, 128, 1)",
                main: "rgba(255, 241, 1, 1)",
                dark: "rgba(230, 217, 1, 1)",
                contrastText: "rgba(50, 64, 66, 1)"
            },
            secondary: {
                light: "rgba(91, 102, 104, 1)",
                main: "rgba(50, 64, 66, 1)",
                dark: "rgba(40, 51, 53, 1)",
                contrastText: "rgba(255, 255, 255, 1)"
            },
            error: {
                light: "#e57373",
                main: "#f44336",
                dark: "#d32f2f",
                contrastText: "#fff"
            },
            text: {
                primary: "rgba(50, 64, 66, 1)",
                secondary: "rgba(0, 0, 0, 0.54)",
                disabled: "rgba(0, 0, 0, 0.38)",
                hint: "rgba(0, 0, 0, 0.38)"
            },
            action: {
                primary: "rgba(50, 64, 66, 0.87)",
            }
        },
        overrides:{
            MuiLink: {
                root: {
                    color: "rgba(91, 102, 104, 1)",
                }
            }
        }
    },
}

export const createAppState = ( defaultTheme: any = baseTheme ) => {
    const store = createStore(
        rootReducer,
        applyMiddleware(thunk)
    );

    // apply initial store actions
    store.dispatch(addTheme('default', defaultTheme));

    return store;
};
