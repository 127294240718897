import { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface variablesType {
  [key: string]: any
}

export type translateFuncType = (id: string, variables?: variablesType) => string

export default function useTranslate() {
  const intl = useIntl();
  const translate = useCallback<translateFuncType>(
    (id, variables = {}) => intl.formatMessage({ id }, variables),
    [intl]
  );

  return {
    translate,
  };
}
