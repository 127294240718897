import {ContractBillOverview, TransformedCDR} from "../state/contracts/types";
import de from './../translations/de.json';
import {TrasnlationsType} from "../providers/language-provider";

const translations: TrasnlationsType = {
    de
};

export const jsonToCSVConverter = (JSONData: any, bill: ContractBillOverview, ShowLabel: boolean) => {
    let arrData = JSONData;
    arrData = cdrTransformer(arrData);
    let CSV = '';
    //CSV += ReportTitle + '\r\n\n';
    //This condition will generate the Label/Header
    if (ShowLabel) {
        let row = "";
        //This loop will extract the label from 1st index of on array
        for (let index in arrData[0]) {
            row += translations.de['csv.' + index] + ';';
        }
        row = row.slice(0, -1);
        CSV += row + '\r\n';
    }

    for (let i = 0; i < arrData.length; i++) {
        let row = "";
        for (let index in arrData[i]) {
            row += '"' + arrData[i][index] + '";';
        }
        row.slice(0, row.length - 1);
        CSV += row + '\r\n';
    }

    if (CSV == '') {
        alert("Invalid data");
        return;
    }

    //Generate a file name
    let fileName = bill.contract_id + '-' + bill.billing_year + '-' + bill.billing_month + '-' + translations.de['csv.charge_sessions'];
    //this will remove the blank-spaces from the title and replace it with an underscore
    //fileName += fileName.replace(/ /g,"_");

    const uri = 'data:text/csv;charset=utf-8,%EF%BB%BF'+encodeURIComponent(CSV);
    let link = document.createElement("a");
    link.href = uri;
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    console.log(CSV);
}

export const cdrTransformer = (json: any) => {
    let transformedData: TransformedCDR[] = [];
    json.map((data: any) => {
        console.log(data);
        data.charge_sessions.map((session: any) => {
            let transformedSession: TransformedCDR = <TransformedCDR>{};
            transformedSession.id = session.id;
            transformedSession.card_id = data.cardid;
            transformedSession.evse_id = session.evseid;
            transformedSession.chargepoint_address = session.chargepoint_address;
            transformedSession.chargetype = session.chargetype;
            transformedSession.is_roaming_station = session.is_roaming_station;
            transformedSession.ts_start = session.ts_start;
            transformedSession.ts_end = session.ts_end;
            transformedSession.metertotal_kwh = session.metertotal_kwh;
            transformedSession.price_per_unit_net = session.price_per_unit_net;
            transformedSession.total = (session.metertotal_kwh * session.price_per_unit_net).toFixed(2).replace('.', ',');
            transformedData.push(transformedSession);
        })
    });

    return transformedData;
}