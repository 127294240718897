import React from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ErrorIcon from '@material-ui/icons/Error';

import { AppState } from '../state/types';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'fixed',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width : '100vw',
            height : '100vh',
        },
        icon: {
            fontSize: '5rem',
            color: theme.palette.error.dark,
        },
        message: {
            maxWidth: '50%',
            marginTop: theme.spacing(3),
        },
        helpLink: {
            marginTop: theme.spacing(4),
        }
    })
);

/* TODO: define it in better way
function getStructuredError(error: string): AppError {
    return {
        code: 100,
        message: 'Message',
        detialMessage: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
        helpUrl: 'https://www.google.com'
    }
}
*/

const AppUIError: React.FC = () => {
    const classes = useStyles();
    const { error } = useSelector((state: AppState) => state.config);
    if(!error) {
        // do not show an error, if there is no...
        return <></>;
    }

    const { message, detailMessage, helpUrl } = error;
    return (
        <div className={classes.root}>
            <ErrorIcon className={classes.icon} />
            <Typography className={classes.message} variant="h4" align="center">
                {message}
            </Typography>
            <Typography className={classes.message} variant="h6" align="center">
                {detailMessage}
            </Typography>
            {helpUrl && <Link className={classes.helpLink} href={helpUrl} >
                Read more !
            </Link>}
        </div>
    )
}

export default AppUIError
