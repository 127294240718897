import { MenuItem } from '../../components/navigation/navigation';
import { translateFuncType } from './../../hooks/use-translate';

import UserDetail from '../user-detail';
import Contracts from '../contracts';
import Overview from '../overview';
import ChargeCards from '../charge-cards';
import Playground from '../playground';

// TODO check if there is a better place for this
const getMenuItems = (translate: translateFuncType) => {
    const menuItems: MenuItem[] = [
        {
            name: translate('navigation.label.overview'),
            icon: "blur_circular",
            slug: "/overview",
            visible: true,
            component: Overview
        },
        {
            name: translate('navigation.label.user'),
            icon: "account_circle",
            slug: "/account",
            visible: true,
            component: UserDetail
        },
        {
            name: translate('navigation.label.contracts'),
            icon: "assignment",
            slug: "/contracts",
            visible: true,
            component: Contracts
        },

        {
            name: translate('navigation.label.charge_cards'),
            icon: "credit_card",
            slug: "/customer/:customerId/contracts/:contractId/:yearmonth",
            title: "BOOO",
            visible: false,
            component: ChargeCards
        },
        {
            name: translate('navigation.label.playground'),
            icon: "pool",
            slug: "/playground",
            visible: false,
            component: Playground
        }
    ];
    return menuItems;
}

export default getMenuItems;
