import { connect } from 'react-redux';
import { AppState } from '../../state/types';
import ChargeCards from './charge-cards';

const mapStateToProps = (state: AppState) => {
    return {
      contracts: state.contracts,
    }
}

const mapDispatchToProps = () => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargeCards);
