import { Dispatch } from 'redux';
import { setContractsData, setContractsError, setContractsLoading, clearContractsError, setContractBillDetails } from './actions';
import { Contract, ContractBillDetail } from './types';

import { API_NAME } from '../types';
import { createApiURL } from '../../core/api-utils';
import { API, Auth } from 'aws-amplify';

export const fetchContractInfo = () => async (dispatch: Dispatch) => {
    dispatch(setContractsLoading(true));

    const user = await Auth.currentSession().catch(ex => { console.log("No user session existing"); });
    
    if (!user) {
        // TODO show the user an error indicating that something went wrong
        dispatch(setContractsLoading(false));
        return;
    }

    const {
        sub: userId
    } = user.getIdToken().payload;

    const res = await API.get(API_NAME, createApiURL(`customer/${userId}/contracts`), {});
    dispatch(setContractsData(res));

    dispatch(setContractsLoading(false));
}

export const fetchContractBillOverviewInfo = () => async (dispatch: Dispatch) => {

    // const contractBillDetail: ContractBillDetail[] = CHARGE_CARD_BILL_MONTH_CDR

    // dispatch(setContractBillDetails(contractBillDetail));
}
