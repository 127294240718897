import { connect } from 'react-redux';
import { AppState } from '../../state/types';
import Overview from './overview';

const mapStateToProps = (state: AppState) => {
    return {
        contracts: state.contracts
    }
}

const mapDispatchToProps = () => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
