export interface AppError {
    code?: number;
    original?: Error | any;
    message: string;
    detailMessage: string;
    helpUrl?: string;
}

export interface ErrorConstructor {
    original?: Error | any;
    message?: string;
    detailMessage?: string;
    helpUrl?: string;
}

export abstract class UIErrorFactory {

    private static errors: { [code: number]: AppError } = {
        // config errors
        1000: {
            message: 'Failed to get Application Config',
            detailMessage: 'Lorem ipsum'
        },
        1010: {
            message: 'Failed to obtain authentication configuration',
            detailMessage: 'Lorem ipsum'
        }
    };

    public static constructfromCode(code: number, optionalDetails?: ErrorConstructor): AppError {

        // get error shell
        const template = UIErrorFactory.errors[code];

        // optional parameters
        const { 
            message = template.message, 
            detailMessage = template.detailMessage, 
            helpUrl  = template.helpUrl,
            original = null
        } = optionalDetails || {};

        // return copy with extended props
        const error = {
            ...template,
            code,
            message,
            detailMessage,
            helpUrl,
            original,
        };

        console.warn('UI Error was constructed:', error);

        return error;
    }
}