import { ThemeState } from "./theme/types";
import { ConfigState } from "./config/types";
import { ContractsState } from "./contracts/types";
import { UserState } from './user/types';

export * from './config/types';
export * from './contracts/types';
export * from './theme/types';
export * from './user/types';

export const API_NAME = 'CSSServerEndpoint';
export const BILLING_KEY = 'platform:billing_id';

export type AppState = {
    theme: ThemeState;
    config: ConfigState;
    contracts: ContractsState;
    user: UserState;
}

export interface AppError {
    code?: number;
    original?: Error | any;
    message: string;
    detialMessage: string;
    helpUrl?: string;
}

export interface ErrorConstructor {
    original?: Error | any;
    message?: string;
    detailMessage?: string;
    helpUrl?: string;
}

export abstract class UIErrorFactory {

    private static errors: { [code: number]: AppError } = {
        100: {
            message: '',
            detialMessage: ''
        }
    };

    public static constructfromCode(code: number, { original, message, detailMessage, helpUrl }: ErrorConstructor): AppError {
        // get error shell
        const template = UIErrorFactory.errors[code];

        // return copy with extended props
        return {
            ...template,
            code,
            original,
            message: message || template.message,
            detialMessage: detailMessage || template.detialMessage,
            helpUrl: helpUrl || template.helpUrl
        };
    }
}
