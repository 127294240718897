import { AppError } from '../errors';

export const SET_USER_LOADING = 'user/set/loading';
export const SET_USER_DATA = 'user/set/data';
export const CLEAR_USER_DATA = 'user/clear/data';
export const SET_USER_ERROR = 'user/set/error';
export const CLEAR_USER_ERROR = 'user/clear/error';

export interface Address {
    street: string;
    houseNumber: string;
    zip: string;
    city: string;
    country: string;
}

export interface CSSUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    address: Address;
}

export interface UserState {
    isLoading: boolean;
    user?: CSSUser;
    error?: AppError;
}

export interface SetUserLoading {
    type: typeof SET_USER_LOADING;
    isLoading: boolean;
}

export interface SetUserData {
    type: typeof SET_USER_DATA;
    userData: CSSUser;
}

export interface ClearUserData {
    type: typeof CLEAR_USER_DATA;
}

export interface SetUserError {
    type: typeof SET_USER_ERROR;
    error?: AppError;
}

export interface ClearUserError {
    type: typeof CLEAR_USER_ERROR;
}

export type UserActionTypes = SetUserLoading | SetUserData | SetUserError | ClearUserError | ClearUserData;