import React, {FC, useMemo, useState} from 'react';
import { FormattedMessage } from 'react-intl';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {ContractBillOverview, ContractsState} from './../../state/contracts/types';
import useConverter from './../../hooks/use-converter';
import SummaryCard from './components/summary-card';
import Feed from './components/feed';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { createApiURL, getAPIParams, downloadItem, getCDRJSON } from '../../core/api-utils';
import ContractRow from "../contracts/components/contract-row";
import {jsonToCSVConverter} from "../../core/csv-utils";

export interface OverviewProps {
	contracts: ContractsState;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		gridItem: {
			width: '100%',
		},
		footerItem: {
			marginRight: theme.spacing(0.2),
			marginBottom: theme.spacing(1),
		},
	})
);

const Overview: FC<OverviewProps> = ({ contracts }) => {
	const classes = useStyles();
	const { convertNumberToKwh } = useConverter();
	//let bills: ContractBillOverview[] = [];
	const [invoiceLoading, setInvoiceLoading] = useState(false);
	const [cdrLoading, setCdrLoading] = useState(false);
	const [csvLoading, setCSVLoading] = useState(false);
	// Fix for safari and firefox toLocaleString() error invalid date
	const mlist = [ "Undefined", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ];
	const { totalSessions, totalKwh, totalCards, bills, reducedBills } = useMemo(() => {
		let totalSessions = 0;
		let totalKwh = 0;
		let totalCards = 0;
		let bills: any[] = [];

		contracts.contracts.forEach((item) => {
			totalCards += item.cards.length;
			item.bills.forEach((bill) => {
				totalSessions += bill.aggregates.total_sessions ? bill.aggregates.total_sessions : 0;
				totalKwh += bill.aggregates.total_kwh ? bill.aggregates.total_kwh : 0;
				bill.contract_name = item.name;
				bills.push(bill);
			});
		});

		let reducedBills = bills;

		reducedBills.sort((a,b) => (parseInt(b.billing_year + b.billing_month) - parseInt( a.billing_year + a.billing_month)));

		return {
			totalSessions,
			totalKwh,
			totalCards,
			bills,
			reducedBills
		};
	}, [contracts]);


	const downloadInvoice = async (bill: ContractBillOverview) => {
		setInvoiceLoading(true);
		await downloadItem(bill.contract_id, bill.billing_year, bill.billing_month, 'invoice', bill.invoice_id);
		setInvoiceLoading(false);
	}

	const downloadCDR = async (bill: ContractBillOverview) => {
		setCdrLoading(true);
		await downloadItem(bill.contract_id, bill.billing_year, bill.billing_month, 'pdf');
		setCdrLoading(false);
	}

	const downloadCSV = async (bill: ContractBillOverview) => {
		setCSVLoading(true);
		const json = await getCDRJSON(bill.contract_id, bill.billing_year, bill.billing_month);
		await jsonToCSVConverter(json.charges_by_card, bill, true);
		setCSVLoading(false);
	}

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={6} md={6} lg={3} className={classes.gridItem}>
					<SummaryCard
						icon='assignment'
						cardLink='/contracts'
						title={<FormattedMessage id='dashboard.tile.title.contract' />}
						content={`${contracts.contracts.length}`}
						footer={<FormattedMessage id='dashboard.label.contractSummary' />}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} className={classes.gridItem}>
					<SummaryCard
						icon='credit_card'
						cardLink='/contracts'
						title={<FormattedMessage id='dashboard.tile.title.cards' />}
						content={`${totalCards}`}
						footer={<FormattedMessage id='dashboard.label.cardsSummary' />}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} className={classes.gridItem}>
					<SummaryCard
						icon='access_alarm'
						cardLink='/contracts'
						title={<FormattedMessage id='dashboard.tile.title.totalSessions' />}
						content={`${totalSessions}`}
						footer={<FormattedMessage id='dashboard.label.totalSessionsSummary' />}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} className={classes.gridItem}>
					<SummaryCard
						icon='flash_on'
						cardLink='/contracts'
						title={<FormattedMessage id='dashboard.tile.title.totalKwh' />}
						content={
							<>
								{convertNumberToKwh(totalKwh)} <FormattedMessage id='measurement.kwh' />
							</>
						}
						footer={<FormattedMessage id='dashboard.label.totalKwhSummary' />}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<h2>Was gibt's Neues ?</h2>
				</Grid>
				<Grid item xs={12} sm={8}>
					{reducedBills.slice(0,5).map((bill, index) => (
						<Feed
							key={`bill-${index}`}
							topInfo={`${mlist[parseInt(bill.billing_month, 10)]} ${bill.billing_year} - Neuer Beleg`}
							title={`Ein neuer Beleg für den Vertrag ${bill.contract_name} liegt für Sie bereit.`}
							footer={
								<>
									<Button className={classes.footerItem} variant="contained" color="primary" disabled={invoiceLoading} size="small" onClick={() => downloadInvoice(bill)}>
										<FormattedMessage id="label.receipt" />
									</Button>
									{
										bill.aggregates.cards && <Button className={classes.footerItem} variant="contained" color="secondary" disabled={cdrLoading} size="small" onClick={() => downloadCDR(bill)}>
											<FormattedMessage id="label.CDR" />
										</Button>
									}
									{
										bill.aggregates.cards &&  <Button className={classes.footerItem} variant="contained" color="secondary" disabled={csvLoading} size="small" onClick={() => downloadCSV(bill)}>
											<FormattedMessage id="label.CSV" />
										</Button>
									}
								</>
							}
						/>
						))}
				</Grid>
			</Grid>
		</>
	);
};

export default Overview;
