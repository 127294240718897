import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import RootLayout from './root-layout';
import { AppState } from '../../state/types';

import { clearUserData } from '../../state/actions';
import { fetchUserInfo } from '../../state/operations';

const mapStateToProps = ({ theme }: AppState) => {
    return {
        currentTheme: theme.themes[theme.currentTheme]
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchUserInfo: () => dispatch(fetchUserInfo() as any),
        clearUserData: () => dispatch(clearUserData())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RootLayout);
