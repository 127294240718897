import React, { FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { UserState } from './../../state/user/types';
import UserDetails from './components/user-details';
import LoadingIndicator from './../../components/loading-indicator';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingRoot: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
        },
    })
);

export interface UserDetailProps {
    user: UserState
}

const UserDetail: FC<UserDetailProps> = ({ user: { user, isLoading} }) => {
    const classes = useStyles();
    if(isLoading) {
        return (
            <div className={classes.loadingRoot}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <UserDetails user={user}/>
    );
};

export default UserDetail;
