import { SET_CONFIG_DATA, SET_CONFIG_LOADING, SET_CONFIG_PATH, SET_CONFIG_ERROR,
    SetConfigData, SetConfigLoading, SetConfigPath, SetConfigError, ClearConfigError, ConfigData, CLEAR_CONFIG_ERROR } 
from './types';
import { AppError } from '../errors';

export const setConfigData = (data: ConfigData): SetConfigData => ({
    type: SET_CONFIG_DATA,
    data
});

export const setConfigLoading = (loading: boolean): SetConfigLoading => ({
    type: SET_CONFIG_LOADING,
    loading
});

export const setConfigPath = (path: string): SetConfigPath => ({
    type: SET_CONFIG_PATH,
    path
});

export const setConfigError = (error?: AppError): SetConfigError => ({
    type: SET_CONFIG_ERROR,
    error
});

export const clearConfigError = (): ClearConfigError => ({
    type: CLEAR_CONFIG_ERROR
});