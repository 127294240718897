
const headCells: Array<object> = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'x', numeric: true, disablePadding: false, label: 'X value' },
    { id: 'y', numeric: true, disablePadding: false, label: 'Y value' },
    { id: 'z', numeric: true, disablePadding: false, label: 'Z value' },
];

const rows: Array<object> = [
        {
            name: 'Test',
            x: 305,
            y: 3.7,
            z: 67
        },
        {
            name: 'Yes',
            x: 452,
            y: 25.0,
            z: 51
        },
        {
            name: 'No',
            x: 262,
            y: 16.0,
            z: 24
        },
        {
            name: 'Call them',
            x: 159,
            y: 6.0,
            z: 24
        },
        {
            name: 'Good',
            x: 356,
            y: 16.0,
            z: 49
        },
        {
            name: 'Nice',
            x: 408,
            y: 3.2,
            z: 87
        },
        {
            name: 'The best',
            x: 237,
            y: 9.0,
            z: 37
        },
        {
            name: 'Solution',
            x: 375,
            y: 0.0,
            z: 94
        },
        {
            name: 'Mountain',
            x: 518,
            y: 26.0,
            z: 65
        },
        {
            name: 'It is',
            x: 392,
            y: 0.2,
            z: 98
        },
        {
            name: 'Bird',
            x: 318,
            y: 0,
            z:81
        },
        {
            name: 'Goat',
            x: 360,
            y: 19.0,
            z: 9
        },
        {
            name: 'House',
            x: 437,
            y: 18.0,
            z: 63
        }
];

const tableData = {
        headCells: headCells,
        data: rows,
        metaData: {
            count: rows.length,
        }
}

export default tableData
