import React, { FC, useEffect, useState } from 'react';
import {
    useLocation,
    useHistory
} from 'react-router-dom';

import { API_BASE } from '../../core/api-utils';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type ResetPasswordRedirectProps = {};

const ResetPasswordRedirect: FC<ResetPasswordRedirectProps> = () => {
    const query = useQuery();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(query.get('uid') && query.get('chl')) {
            window.location.href = `${API_BASE}/customer/${query.get('uid')}/resend-password/${query.get('chl')}`;
        }
    }, []);

    return <></>;
}

export default ResetPasswordRedirect;
