import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';

interface FeedProps {
 topInfo: string
 title: string
 footer?: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: "#e7e6e6",
            marginBottom: theme.spacing(1.5),
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
        },
        avtarImage: {
            width: '100%',
        },
        content: {
            padding: theme.spacing(0.5),
        },
        footer: {
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginTop: theme.spacing(1),
            minHeight: theme.spacing(2),
            justifyContent: 'flex-end',
            [theme.breakpoints.down('xs')]: {
                flexWrap: 'wrap'
            }
        },
    })
);

const Feed: React.FC<FeedProps> = ({
    topInfo,
    title,
    footer
}) => {
    const classes = useStyles();
    return (
        <Card className={classes.root} >
            <CardContent className={classes.content}>
                <Box display="flex" alignItems="center">
                    <Box width="32px" mr={1}>
                        <img className={classes.avtarImage} src="/ewe-logo-circle.png" alt="avtr"></img>
                    </Box>
                    <span>{topInfo}</span>
                </Box>
                <Box component="h3" m={0} mt={0.5}>
                    {title}
                </Box>
            </CardContent>
            <CardActions className={classes.footer}>
                {footer && footer}
            </CardActions>
        </Card>
    )
};

export default Feed;