import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import useConverter from './../../hooks/use-converter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridItem: {
            width: '100%',
        },
     })
);

const kwh = 145.265625
const total_duration_minutes = 12456.25


const ContentBoxAndTables: React.FC = () => {
    const classes = useStyles();
    const { convertSecondsToReadable, convertNumberToKwh } = useConverter()
    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item sm={12} className={classes.gridItem}>
                    <strong>{kwh} kwh =>  conversion => {convertNumberToKwh(kwh)} <FormattedMessage id='measurement.kwh' /></strong>
                </Grid>
                <Grid item sm={12} className={classes.gridItem}>
                    {/* total_duration is in minutes so converting in seconds */}
                    <strong>{total_duration_minutes} mins => duration conversion => {convertSecondsToReadable(total_duration_minutes * 60)}</strong>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContentBoxAndTables
