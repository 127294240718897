import React from 'react';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from './../../state/types';
import { setConfigLoading, setConfigData, setConfigError, clearConfigError } from './../../state/config/actions';
import { UIErrorFactory } from '../../state/errors';

const ErrorAndLoading: React.FC = () => {
    const dispatch = useDispatch()
    const { config } = useSelector((state: AppState) => state.config)

    return (
        <>
            <Button variant="contained" color="primary" onClick={() => {
                dispatch(setConfigLoading(true))
                setTimeout(function() {
                    dispatch(setConfigLoading(false))
                }, 2000)
            }}>
                Loading Screen
            </Button>
            &nbsp;&nbsp;
            <Button variant="contained" color="secondary" onClick={() => {
                const error = UIErrorFactory.constructfromCode(1000);
                dispatch(setConfigError(error))
                setTimeout(function() {
                    dispatch(clearConfigError());
                    config && dispatch(setConfigData(config));
                }, 2000)
            }}>
                Error Screen
            </Button>
        </>
    )
}

export default ErrorAndLoading
