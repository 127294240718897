import React, { FC, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import {
    ContractsState,
    CardChargeDetail,
    ChargeCard,
    ContractBillDetail
} from './../../state/contracts/types';
import LoadingIndicator from './../../components/loading-indicator';
import ChargeCardRow from './components/charge-card-row';

import { downloadItem, getAPIParams} from '../../core/api-utils';
import { API } from 'aws-amplify';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

export interface ChargeCardsProps {
    contracts: ContractsState
}

export type CardBillDetails = CardChargeDetail & ChargeCard;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingRoot: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
        },
        root: {
            width: '100%',
        },
        back: {
            textDecoration: 'none',
            color: 'rgba(55, 55, 60, 1)'
        },
        gridItem: {
            width: '100%',
        }
    })
);
type RouteParams = { customerId: string, contractId: string, yearmonth: string };
const ChargeCards: FC<ChargeCardsProps & RouteComponentProps> = ({ match, contracts: { isLoading, contracts, contractBillDetail } }) => {
    const classes = useStyles();
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [cdrLoading, setCdrLoading] = useState(false);

    const { customerId, contractId, yearmonth } = match.params as RouteParams;
    const billingYear = yearmonth.slice(0, -2);
    const billingMonth = yearmonth.slice(4, 6);

    const [ cdrDetail, setCdrDetail ] = useState<ContractBillDetail>();

    useEffect(() => {
        getAPIParams().then((params) => {
            if(!params) {
                // TODO show error
                return;
            }

            const { API_NAME } = params;

            API.get(API_NAME, `/customer/${customerId}/cdr/${contractId}/${yearmonth}`, {}).then(data => {
                setCdrDetail(data);
            })
        });

    }, [customerId, contractId, yearmonth]);

    if(isLoading || !cdrDetail) {
        return (
            <div className={classes.loadingRoot}>
                <LoadingIndicator />
            </div>
        );
    }
    let cards: CardBillDetails[] = [];
    cdrDetail.charges_by_card.forEach(card => {
        cards.push({
            ...card,
            card_id: card.cardid
        });
    });

    const downloadInvoice = async (contractId: string, billingYear: string, billingMonth: string) => {
        setInvoiceLoading(true);
        await downloadItem(contractId, billingYear, billingMonth,'invoice');
        setInvoiceLoading(false);
    }

    const downloadCDR = async (contractId: string, billingYear: string, billingMonth: string) => {
        setCdrLoading(true);
        await downloadItem(contractId, billingYear, billingMonth, 'pdf');
        setCdrLoading(false);
        // window.history.pushState({}, 'I CHANGED', window.location.href + "#foo");
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6" gutterBottom>
                <FormattedMessage id="label.cardList" />
                {'\u00A0'}
                <Button size="small" variant="contained" color="primary">
                    <Link className={classes.back} to={`/contracts?p=${contractId}:${yearmonth}`} >
                        <FormattedMessage id="label.back" />
                    </Link>
                </Button>
                {'\u00A0'}
                <Button size="small" variant="contained" color="primary" disabled={invoiceLoading} onClick={() => downloadInvoice(contractId, billingYear, billingMonth)}>
                    <FormattedMessage id="label.invoice" />
                </Button>
                {'\u00A0'}
                <Button size="small" variant="contained" color="primary" disabled={invoiceLoading} onClick={() => downloadCDR(contractId, billingYear, billingMonth)}>
                    <FormattedMessage id="label.CDR" />
                </Button>
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={12} className={classes.gridItem}>
                    {cards.map((card) => (<ChargeCardRow key={card.cardid} card={card} />))}
                </Grid>
            </Grid>
        </div>
    );
}


export default withRouter(ChargeCards);
