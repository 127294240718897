import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import useTranslate, { translateFuncType } from './../../../hooks/use-translate';
import { CardChargeDetail, ChargeCard } from './../../../state/contracts/types';
import DataTable from './../../../components/data-table';

export type CardBillDetails = CardChargeDetail & ChargeCard;

export interface ChargeCardRowProps {
    card: CardBillDetails;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightBold,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        column: {
            flexBasis: '33.33%',
        },
        panelDetailsOverride: {
            padding: 0,
        },
    })
);


const getHeadCells = (translate: translateFuncType)  => {
    const headCells: Array<object> = [
        { id: 'tarif', numeric: false, disablePadding: false, label: translate('tabel.header.tarif') },
        { id: 'evseid', numeric: false, disablePadding: false, label: translate('tabel.header.evseid') },
        { id: 'chargepoint_address', numeric: false, disablePadding: false, label: translate('tabel.header.chargepoint_address') },
        { id: 'metertotal', numeric: true, disablePadding: false, label: translate('tabel.header.metertotal') },
        { id: 'metertotal_kwh', numeric: true, disablePadding: false, label: translate('tabel.header.metertotal_kwh') },
    ];
    return headCells;
}

const ChargeCardRow: FC<ChargeCardRowProps> = ({ card }) => {
    const classes = useStyles();
    const { translate } = useTranslate();
    const tableData = card.charge_sessions.map(({
        tarif,
        evseid,
        chargepoint_address,
        metertotal,
        metertotal_kwh
    }) => ({
        tarif,
        evseid,
        chargepoint_address,
        metertotal,
        metertotal_kwh,
    }));
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                id={`card-panel-${card.cardid}-header`}
            >
                <div className={classes.column}>
                    <Typography className={classes.heading}>{card.cardid}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography className={classes.secondaryHeading}><FormattedMessage id="label.chargedSessions" values={{ number: card.charge_sessions.length}} /></Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                classes={{
                    root: classes.panelDetailsOverride
                }}
            >
                <DataTable
                    data={{
                        headCells: getHeadCells(translate),
                        data: tableData,
                        metaData: {
                            count: card.charge_sessions.length,
                        }
                    }}
                />
            </ExpansionPanelDetails>
            <Divider />
            {/*<ExpansionPanelActions>
                <Button size="small" color="primary">
                    Some Action
                </Button>
            </ExpansionPanelActions>*/}
        </ExpansionPanel>
    );
};

export default ChargeCardRow;
