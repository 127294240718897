import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        titleWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        titleLink: {
            opacity: 1,
            height: '2.5rem',
            width: '150px',
            marginRight: '20px',
            marginTop: '-1rem',
            marginBottom: '-1rem',
            transition: theme.transitions.create(['width', 'opacity'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('xs')] :{
                width: '100px',
            }
        },
        titleLinkHide: {
            pointerEvents: 'none',
            width: '0px',
            opacity: 0,
            transition: theme.transitions.create(['width', 'opacity'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        },
        titleImage: {
            height: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        appBar: {
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.secondary.contrastText,
            overflow: 'hidden',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.down('xs')] :{
                position: 'fixed',
            }
        },
        appBarShift: {
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
            }
        },
        menuButton: {
            marginRight: 0,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('xs')]: {
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
            },
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('xs')]: {
                zIndex: theme.zIndex.drawer + 2,
            }
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(8) + 1,
            },
        },
        headerToolbar: {
            backgroundColor: theme.palette.common.black,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            paddingBottom: theme.spacing(10),
            overflowX: 'hidden',
            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(7) + 1,
                padding: theme.spacing(1),
                paddingBottom: theme.spacing(10),
            },
        },
    }),
);

    export default useStyles
