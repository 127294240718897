import React, { FC, useCallback, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useStyles } from './contract-row';
import useConverter from './../../../hooks/use-converter';
import { ContractBillOverview } from './../../../state/contracts/types';
import useTranslate from './../../../hooks/use-translate';

import { createApiURL, getAPIParams, downloadItem, getCDRJSON } from '../../../core/api-utils';
import { jsonToCSVConverter } from '../../../core/csv-utils';
import { parseUrl } from '../../../core/url-utils';
import { API } from 'aws-amplify';

export interface BillingDetailsProps {
    bills: ContractBillOverview[];
}

const BillingDetails: FC<BillingDetailsProps> = ({ bills }) => {

    const classes = useStyles();
    const { convertSecondsToReadable, convertNumberToKwh } = useConverter()

    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [cdrLoading, setCdrLoading] = useState(false);
    const [csvLoading, setCSVLoading] = useState(false);
    /*const [apiParams, setApiParams] = useState();

    /*useEffect(() => {
        if(!apiParams) {
            console.log("Load api params");
            getAPIParams().then(params => {
                console.log("Params", params);
                setApiParams(params);
            });
        }

    }, [apiParams]);*/

    /*const downloadItem = async (bill: ContractBillOverview, type: 'invoice' | 'pdf') => {
        const params = await getAPIParams();
        if (params) {
            const { API_NAME, userId } = params;
            const { contract_id, billing_year, billing_month } = bill;
            const { presignedUrl } = await API.get(API_NAME, createApiURL(`customer/${userId}/cdr/${contract_id}/${billing_year}${billing_month}/${type}`), {});
            window.location = presignedUrl;
        } else {
            // TODO throw error, something went wrong here
        }
    }*/

    const downloadInvoice = async (bill: ContractBillOverview) => {
        setInvoiceLoading(true);
        await downloadItem(bill.contract_id, bill.billing_year, bill.billing_month, 'invoice', bill.invoice_id);
        setInvoiceLoading(false);
    }

    const downloadCDR = async (bill: ContractBillOverview) => {
        setCdrLoading(true);
        await downloadItem(bill.contract_id, bill.billing_year, bill.billing_month, 'pdf');
        setCdrLoading(false);
        // window.history.pushState({}, 'I CHANGED', window.location.href + "#foo");
    }

    const downloadCSV = async (bill: ContractBillOverview) => {
        setCSVLoading(true);
        const json = await getCDRJSON(bill.contract_id, bill.billing_year, bill.billing_month);
        await jsonToCSVConverter(json.charges_by_card, bill, true);
        setCSVLoading(false);
    }

    const urlParams = parseUrl(window.location.search);

    const [ panelState, updatePanels ] = useState(urlParams.contracts.reduce((arr, contract) => {
        contract.billDates.forEach(billDate => {
           arr[`${contract.contractId}${billDate}`] = true;
        });
        return arr;
    }, {} as { [id:string]: boolean }));

    const setPanelState = (key: string, value: boolean) => {
        updatePanels({
            ...panelState,
            [key]: value
        });
        // UPDATE THE URL
        console.log("UPDATE URL");
    };

    const sortedBills = React.useMemo(() => {
        return bills.sort((a,b) => (parseInt(b.billing_year + b.billing_month) - parseInt( a.billing_year + a.billing_month)));
    }, [bills]);

    // @ts-ignore
    return (
        <div>
            {
                sortedBills.map((bill, index) => (
                    <ExpansionPanel
                        key={`bill-${index}`}
                        expanded={panelState[`${bill.contract_id}${bill.billing_year}${bill.billing_month}`]}
                        onChange={(_, expanded ) => { setPanelState(`${bill.contract_id}${bill.billing_year}${bill.billing_month}`, expanded) }}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            id={`bill-panel-${index}-header`}
                            classes={{
                                root: classes.panelSummary
                            }}
                        >
                            <div className={classes.column}>
                                <Typography className={classes.heading}>{bill.billing_month} / {bill.billing_year}</Typography>
                            </div>
                            {
                                false && <div className={classes.column}>
                                    <FormattedMessage
                                        id='message.billIncluded'
                                        values={{ number: bill.aggregates.cards.length }}
                                    />
                                </div>
                            }
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            classes={{
                                root: classes.panelDetailsOverride
                            }}
                        >
                            <div className={classes.panelBodyActions}>
                                {
                                    bill.aggregates.cards && <Button variant="contained" color="secondary" disabled={csvLoading} onClick={() => downloadCSV(bill)}>
                                        <FormattedMessage id="label.CSV"/>
                                    </Button>
                                }
                                {
                                    bill.aggregates.cards && <Button variant="contained" color="secondary" disabled={cdrLoading} onClick={() => downloadCDR(bill)}>
                                        <FormattedMessage id="label.CDR" />
                                    </Button>
                                }
                                <Button variant="contained" color="primary" disabled={invoiceLoading} onClick={() => downloadInvoice(bill)}>
                                    <FormattedMessage id="label.receipt" />
                                </Button>
                            </div>
                            {
                                bill.aggregates.cards && <div className={classes.gridItem}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <FormattedMessage id='tabel.header.card'/>
                                                </TableCell>
                                                <TableCell>
                                                    <FormattedMessage id='tabel.header.totalKwh'/>
                                                </TableCell>
                                                <TableCell>
                                                    <FormattedMessage id='tabel.header.totalDuration'/>
                                                </TableCell>
                                                <TableCell>
                                                    <FormattedMessage id='tabel.header.totalSessions'/>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                bill.aggregates.cards && bill.aggregates.cards.map((card) => (
                                                    <TableRow key={`bill=${card.cardid}`}>
                                                        <TableCell>{card.cardid}</TableCell>
                                                        <TableCell>
                                                            {convertNumberToKwh(card.total_kwh)} <FormattedMessage
                                                            id='measurement.kwh'/>
                                                        </TableCell>
                                                        {/* total_duration is in minutes so converting in seconds */}
                                                        <TableCell>{convertSecondsToReadable(card.total_duration * 60)}</TableCell>
                                                        <TableCell>{card.total_sessions}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                            <TableRow>
                                                <TableCell align='right'>
                                                    <strong><FormattedMessage id='tabel.label.total'/></strong>
                                                </TableCell>
                                                <TableCell>
                                                    {convertNumberToKwh(bill.aggregates.total_kwh)}{' '}
                                                    <FormattedMessage id='measurement.kwh'/>
                                                </TableCell>
                                                {/* total_duration is in minutes so converting in seconds */}
                                                <TableCell>{convertSecondsToReadable(bill.aggregates.total_duration * 60)}</TableCell>
                                                <TableCell>{bill.aggregates.total_sessions}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            }
                            {/*
                            // on hold
                            <ExpansionPanelActions>
                                <Button variant="contained" color="primary" >
                                    <Link className={classes.detailLink} to={apiParams? `customer/${apiParams.userId}/contracts/${bill.contract_id}/${bill.billing_year}${bill.billing_month}` : '/contracts'}>
                                        <FormattedMessage id="label.cdrDetail" />
                                    </Link>
                                </Button>
                            </ExpansionPanelActions>
                            */}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))
            }
        </div>
    );
};

export default BillingDetails;
