import Amplify, { Auth } from 'aws-amplify'; 

import { Dispatch } from 'redux';
import { ConfigData } from './types';
import { API_NAME } from '../types';
import { API_BASE } from '../../core/api-utils';
import { setConfigLoading, setConfigError, setConfigData, clearConfigError } from './actions';
import { UIErrorFactory } from '../errors';

export const resolveConfigFromUrl = () => async(dispatch: Dispatch) => {
    console.log(`Running CSS in ${process.env.NODE_ENV}`);
    let configBase = '';
    let dnsDomain = '';
    if('development' === process.env.NODE_ENV){
        configBase = 'https://dq2ejgx8z1w8c.cloudfront.net';
        dnsDomain = 'service-stage.ewe-go.de';
    } else {
        dnsDomain = window.location.hostname;
    }

    const configUrl = `${configBase}/tenant-api/?filter=%7B"dnsDomain"%3A"${dnsDomain}"%7D`;
    console.log(`Loading config from ${configUrl}`);
    await dispatch(loadConfig(configUrl) as any);
} 

export const loadConfig = (location: string|ConfigData) => async (dispatch: Dispatch) => {
    console.log("Loading config");
    // reset error and tell store that config is loading
    dispatch(setConfigLoading(true));
    dispatch(clearConfigError());

    let configData: ConfigData;
    if(typeof location === 'string') {
        // if location is string, try to load it as a url
        configData = await fetch(location)
                            .then(res => res.json())
                            .catch(err => {
                                // catch error
                                const error = UIErrorFactory.constructfromCode(1000, {original: err});
                                dispatch(setConfigError(error));
                            });
    } else {
        configData = location;
    }

    // TODO check configuration integrety 

    // apply configuration to amplify
    try {
        Amplify.configure({
            Auth: {
                region: configData.region, 
                userPoolId: configData.userPoolId, 
                userPoolWebClientId: configData.userPoolWebClientId,
            },
            API: {
                endpoints: [
                    {
                        name: API_NAME,
                        endpoint: API_BASE,
                        custom_header: async () => { 
                            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                        }  
                    }
                ]
            }
        });

    } catch(err) {
        const error = UIErrorFactory.constructfromCode(1010, {original: err});
        dispatch(setConfigError(error));
    }

    // set application data
    dispatch(setConfigData(configData));
    dispatch(setConfigLoading(false));
}