import React from "react";
import {
    BrowserRouter as Router,
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import { AppState } from "./state/types";
import { withAuthenticator, Greetings } from "amplify-material-ui";

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

import ResetPasswordRedirect from './components/reset-password-redirect';
import PasswordNotification from './components/password-notification';
import Footer from './components/footer';
import authMaterialUiDEMessages from './translations/amplify-material-ui-de.json';
import LanguageProvider from './providers/language-provider';
import RootLayout from "./views/root-layout";
import withWaitForConfig from "./hoc/with-wait-for-config"

type AppProps = {};

const WaydoCSSApp: React.FC<AppProps> = () => {
    console.log("Rendering App, you should only see this when you change style...");
    const currentTheme = useSelector((state: AppState) => state.theme.themes[state.theme.currentTheme])
    const theme = createMuiTheme(currentTheme.mui);

    const RootLayoutWithConfig = withWaitForConfig(
        withAuthenticator(RootLayout as any, {
            theme,
            hide: [Greetings],
            hideSignUpLink: true,
            hideForgotPasswordLink: false,
            intlProps: {
                customMessages: {
                    de: authMaterialUiDEMessages
                }
            }
        }));

    return (
        <LanguageProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    <ResetPasswordRedirect />
                    <PasswordNotification />
                    <RootLayoutWithConfig />
                    <Footer />
                </Router>
            </ThemeProvider>
        </LanguageProvider>
    );
}

export default WaydoCSSApp

