
import { Dispatch } from 'redux';
import { API_NAME } from '../types';
import { setUserLoading, setUserData } from './actions';

import { fetchContractInfo } from '../contracts/operations';

import { createApiURL } from '../../core/api-utils';
import { API, Auth } from 'aws-amplify';

import { asyncTimeout } from '../utils';

export const fetchUserInfo = () => async (dispatch: Dispatch) => {
    console.log("Fetching User Info");
    const user = await Auth.currentSession().catch(ex => { console.log("No user session existing"); });

    if (user) {
        dispatch(setUserLoading(true));
        const {
            sub: userId
        } = user.getIdToken().payload;

        const res = await API.get(API_NAME, createApiURL(`customer/${userId}`), {});

        dispatch(setUserData(res));

        // if user was set, dispatch latest contracts
        dispatch(fetchContractInfo() as any);

        dispatch(setUserLoading(false));
    } else {
        console.log("User not logged in.");
        await asyncTimeout(500);
        dispatch(fetchUserInfo() as any);
    }
}