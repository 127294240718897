import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            minWidth: 95,
            width: '100%',
        },
        header: {
            background: theme.palette.primary.main,
        },
        headerTitle: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.palette.primary.contrastText,
        },
        content: {
            width: '100%',
        },
    })
);

interface ContentBoxProps {
    title?: string;
    content?: string | React.ReactElement;
    actions?: React.ReactElement;
    classes?: object;
}

const ContentBox = (props: ContentBoxProps ) => {
    const { title = null, content = 'content', actions = null } = props
    const classes = useStyles(props);
    return (
        <Card className={classes.card}>
            {title && <CardHeader
                className={classes.header}
                classes={{
                    title: classes.headerTitle
                }}
                title={title}
            />}
            <CardContent
                classes={{
                    root: classes.content
                }}
            >
                {content}
            </CardContent>
            { actions &&
                <CardActions>
                    {actions}
                </CardActions>
            }
        </Card>
    )
}

export default ContentBox
